import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// import Dashboard from '../Components/AdminComponents/Dashboard/Dashboard';
import Home from '../Components/Home/Home';
import Login from '../Components/Login/Login';
import Poesia from '../Components/Poesia/Poesia';
import PoesiaLivros from '../Components/PoesiaLivros/PoesiaLivros';
import IncidentesEAlquimias from '../Components/PoesiaLivros/IncidentesEAlquimias';
import NoTapa from '../Components/PoesiaLivros/NoTapa';
import { StoreProvider } from '../Infrastructure/Store/Store';
import PrivateRoute from './PrivateRoute';
import Artigos from '../Components/Artigos/Artigos';
import Artigo from '../Components/Artigos/Artigo';
import Livros from '../Components/Livros/Livros';
import Palestra from '../Components/Palestras/Palestra';
function Rotas() {
  return (
    <div>
      <StoreProvider>
        <BrowserRouter>
            <Routes>
              <Route path="login/*" element={<Login />} />
              <Route path="/" element={<Home />} />
              <Route path="/poesia" element={<Poesia />} />
              <Route path="/poesia-livros" element={<PoesiaLivros />} />
              <Route path="/incidentes-e-alquimias" element={<IncidentesEAlquimias />} />
              <Route path="/no-tapa" element={<NoTapa />} />
              <Route path="/artigos" element={<Artigos/>} />
              <Route path='/artigo/:id' element={<Artigo />} />
              <Route path='/palestra/:id' element={<Palestra />} />
              <Route path='/livros' element={<Livros />} />
              {/* <PrivateRoute path="/dashboard" element={<Dashboard />} /> */}
            </Routes>
        </BrowserRouter>
      </StoreProvider>
    </div>
  );
}

export default Rotas;
