import React  from "react";
import styles from "./Consultoria.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerConsultoria from "../../Assets/ilustracaoTopoConsultoria.jpg";
import TopoConsultoria from "../../Assets/fotoTopoConsultoria.jpg";

const Consultoria = () => {
  return (
    <Container className={styles.generalContent} id="Consultoria">
      <Row>
        <Col xs={12}>
          <div className={styles.banner}>
            <img
              src={BannerConsultoria}
              alt="Banner Consultoria de Leonardo Araujo"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={1}></Col>
        <Col xs={12} md={10}>
          <div className={styles.generalContent}>
            <div className={styles.headerSection}>
              <h1 className="titleSection">CONSULTORIA</h1>
              <p className="subTitleSection">
                Consultoria é fazer junto com o cliente. Não há receita pronta.
                Consultores são eficazes quando trazem boas ferramentas e,
                principalmente, perguntas novas.
              </p>
            </div>
            <img src={TopoConsultoria} alt="Consultoria Leonardo Araujo" />
          </div>
        </Col>
        <Col xs={12} md={1}></Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <p className={styles.terceiroTitulo}>ABORDAGENS</p>
        </Col>
      </Row>
      <Row>
        
          <Col xs={12} md={4}>
            <div className={styles.headerTable}>ESTRATÉGIA</div>
            <p>
              Do mergulho na visão, missão e valores da empresa à formulação de
              objetivos, indicadores e metas, o desafio é construir o melhor e o
              mais rentável posicionamento estratégico. Como consultor nessa
              área, o meu papel é aportar ferramentas e provocar reflexões para
              ajudar estrategistas a traçar rumos certeiros para o negócio e
              obter resultados sustentáveis.
            </p>
          </Col>
          <Col xs={12} md={4}>
            <div className={styles.headerTable}>PROATIVIDADE E INOVAÇÃO</div>
            <p>
              A proatividade de mercado pode turbinar a estratégia de qualquer
              negócio. Proatividade & Inovação andam de mãos dadas. Minha
              proposta de trabalho nessas consultorias é ajudar as empresas a
              refinar sua visão de futuro e ir além da clássica análise SWOT:
              entender melhor as mudanças vindouras no mercado e buscar
              inovações de antecipação.
            </p>
          </Col>
         
          <Col xs={12} md={4}>
            <div className={styles.headerTable}>MARKETING</div>
            <p>
              Marketing é um exercício de renovação permanente. As batalhas de
              mercado nunca estão definitivamente vencidas. Nas consultorias de
              marketing ajudo as empresas a segmentar clientes, a entender quais
              são os melhores alvos no mercado e a definir e posicionar as
              propostas de valor de forma diferenciada e mais lucrativa em
              relação aos concorrentes.
            </p>
          </Col>
        
      </Row>
      <Row>
        <Col>
        <div style={{padding:'1em 0'}}>
          <a
            href="https://www.proatividademercado.com.br"
            rel="noreferrer"
            target="_blank"
            className={styles.saibaMais}
          >
            SAIBA MAIS SOBRE PROATIVIDADE DE MERCADO
          </a>
        </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Consultoria;
