import React from "react";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logo.png";
import Youtube from "../../Assets/youTube.png";
import Facebook from "../../Assets/facebook.png";
import Linkedin from "../../Assets/linkedin.png";

const Header = () => {
  return (
    <header>
      <div className="container">
        <div className={styles.header}>
          <div className={styles.firstHeader}>
            <ul>
              <li>
                <a href="https://www.youtube.com/channel/UCb_6PQWuYzvqkICEwpd7aTQ" target="_blank"  without rel="noreferrer" >
                  <img src={Youtube} alt="Youtube Leonardo Araujo" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/leonardo.associado" target="_blank"  without rel="noreferrer" >
                  <img src={Facebook} alt="Facebook Leonardo Araujo" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/araujo-leonardo/" target="_blank"  without rel="noreferrer" >
                  <img src={Linkedin} alt="Linkedin Leonardo Araujo" />
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.secondHeader}>
            <Link className={styles.logo} to="/" aria-label="Dogs - Home">
              <img src={Logo} alt="Logo Leonardo Araujo" />
            </Link>
          </div>
          <div className={styles.thirdHeader}>
            <ul>
              <li>
                <a href="/#MinhaHistoria">
                  MINHA HISTÓRIA
                </a>
              </li>
              <li>
                <a href="/#Consultoria">
                  CONSULTORIA
                </a>
              </li>
              <li>
                <a href="/#Palestras">
                  PALESTRAS
                </a>
              </li>
              <li>
                <a href="/#Conteudos">
                  CONTEÚDOS
                </a>
              </li>
              <li>
                <a href="/#Contato">
                  CONTATO
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
