import {
    repoGetAllArticles,
    repoGetArticle,
    repoCreateArticle,
    repoDeleteArticle
  } from "../Repositories/Article";
  
  
  export const getAllArticles = async () => {
    try {
      const response = await repoGetAllArticles();
      return response;
    } catch (error) {
      throw error.response;
    }
  };

  export const getArticle = async (articleId) => {
    try {
      const response = await repoGetArticle(articleId);
      return response;
    } catch (error) {
      throw error.response;
    }
  };
  

  export const createArticle = async (articleInfos) => {
    try {
      const response = await repoCreateArticle(articleInfos);
      return response;
    } catch (error) {
      throw error.response;
    }
  };
  
  export const deleteArticle = async (articleId) => {
    try {
      const response = await repoDeleteArticle(articleId);
      return response;
    } catch (error) {
      throw error.response;
    }
  };
  

