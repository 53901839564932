import React  from "react";
import styles from "./Poesia.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerPoesia from "../../Assets/ilustracaoTopoPoesia.jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer"

const Poesia = () => {
  const primeiraParteHistoria = () => {
    return (
      <Container className={styles.generalContent}>
        <Row>
          <Col xs={12}>
            <div className={styles.banner}>
              <img
                src={BannerPoesia}
                alt="Banner de Poesia de Leonardo Araujo"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}></Col>
          <Col xs={12} md={8}>
            <div className={styles.generalContent}>
              <div className={styles.headerSection}>
                <h1 className="titleSection">POESIA</h1>
              </div>
            </div>
          </Col>
          <Col xs={12} md={2}></Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className={styles.botaoPoesia}>
              <button className={styles.botaoTextPoesia} onClick={redirectLivros}>VER LIVROS</button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6}>
            <div className={styles.textoInteiro}>
              <p>
                A poesia tem me ensinado muito. Aqui revelo a lição maior: depois
                de muitos anos não misturando o poeta com outros personagens da
                minha vida profissional (o consultor, o professor, o palestrante),
                aprendi que tudo se mescla. Em essência, somos uma pessoa só.
                Nossos papéis na vida são diferentes matizes do mesmo quadro,
                roteiros do mesmo filme.
              </p>
            </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={3}>
          <div className={styles.textoLateral}>
            <p>Aprender a ler o mundo com palavras de simples linhagem. Traduzir o
            que há de mais fundo com elementos da alma e linguagem.</p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6}>
          <div className={styles.textoInteiro}>
            <p>
              As lições da poesia são humanas e me ajudam a perceber muitas
              situações no mundo da gestão. Vejo empresas inovando pouco porque
              falta aos gestores sensibilidade para misturar essências e
              promover sinergia entre as pessoas.
            </p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6}>
          <div className={styles.textoInteiro}> 
            <p>
              Inovação começa com gente querendo mudar as coisas. Começa com
              algo que falta, com insatisfação e, sobretudo, com bons erros.
              Tudo isso funciona como fagulha para a empresa fazer o novo. É o
              que eu chamo de “viés humano da inovação”. Depois, vem a técnica,
              o processo.
            </p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={3}>
          <div className={styles.textoLateral}>
            <p>
            Dísticos, perfeitos acrósticos, sonetos de pura construção, versos
            livres com brilho de neon nunca revelam o meu destino, nem o enredo
            do próximo sonho. Ainda assim me atrevo e aqui lavro.</p>
            </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6}>
          <div className={styles.textoInteiro}> 
            <p>
              A inovação não é o único campo que me faz entender o elo entre
              poesia e gestão. Quando se trata de estratégia, também percebo a
              conversa entre os dois mundos. Entre planejar e executar a
              estratégia, muita coisa acontece (ou não acontece... para pesadelo
              dos gestores).
            </p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6}>
          <div className={styles.textoInteiro}>  
            <p>
              Com a poesia não é diferente: entre o sentir e o fazer poético há
              um difícil caminho. Um poema nunca vai para o papel de imediato.
              Primeiro, ele fica hibernando no pensamento e só depois salta na
              cachoeira em branco do papel e o poeta começa seu trabalho.
            </p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6}>
          <div className={styles.textoInteiro}>
            <p>
              Como nos ensinou João Cabral, o poeta-artesão-ourives: “O ferro
              fundido é sem luta / é só derramá-lo na forma. / Não há nele a
              queda de braço / e o cara a cara de uma forja”.
            </p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={3}>
          <div className={styles.textoLateral}>
            <p>
            E que a poesia não se cale, nem fique presa, mas que nasça bem ao
            seu modo, em longos anos, até mesmo a fórceps, de qualquer forma,
            aos trancos. Mas que venha, floresça, fale, no tapa. Pior (como
            morrer) é não a ter nunca.</p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6}>
          <div className={styles.textoInteiro}>
            <p>
              A poesia, os livros de gestão e a experiência prática me ensinaram
              que as empresas vencedoras são feitas por pessoas inventivas que
              se jogam na cachoeira, fazem acontecer.
            </p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6}>
          <div className={styles.textoInteiro}>
            <p>
              Pessoas, sistemas, processos, objetivos e metas: uma engrenagem
              complexa, cujo manejo exige determinação (querer), destreza
              (técnica) e sensibilidade (viés humano). Gestores são artífices de
              resultados. Diante da incerteza, deveriam buscar novas formas de
              ver e de agir. Deveriam se atirar mais. Banho de cachoeira só faz
              bem.
            </p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={3}>
          <div className={styles.textoLateral}>
            <p>Provei dos quatro elementos. Não cruzei com os quatro cavaleiros.
            Lancei dardos de barro ao infinito. Não mirei Halley em azul viagem.
            <br />
            <br />
            Decifrei óbvios enigmas. Não entrei para a História. Entre o pólen e
            o fogo, não hesitei, sobrevivo.</p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6}>
          <div className={styles.textoInteiro}>
            <p>
              A poesia não floresce sem criatividade & disciplina, persistência
              & flexibilidade, sonho & realidade, erros & acertos. No mundo da
              gestão nada disso é igualmente dispensável. Enfim, tudo se mescla.
            </p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6}>
          <div className={styles.textoInteiro}>
            <p>
              Poesia tem luz. Mas não é um clarão fácil, desses que surgem do
              nada, de graça, incidente fortuito. Ao contrário, custa muito esse
              brilho. Por isso, faz tanto sentido.
            </p>
          </div>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col></Col>
          <Col xs={12} md={6}>
          <div className={styles.textoLateral}>  
            A semente da fruta conhece bem o escuro.
          </div>
          </Col>
        </Row>
        <Row>
          <Col></Col>
          <Col xs={12} md={10}>
          <div className={styles.textoInteiro}>
            <p>
              Todos os dias esse verso me diz algo diferente e me faz seguir
              semeando palavras.
            </p>
          </div>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <strong><a href="/">Voltar</a></strong>
        </Row>
      </Container>
    );
  };

  const redirectLivros = () =>{
    window.location.href="/poesia-livros"
  }
  return (
    <>
      <Header />
      <div className="container">{primeiraParteHistoria()}</div>
      <Footer/>
    </>
  );
};

export default Poesia;
