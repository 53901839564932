import React  from "react";
import styles from "./Artigos.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerArtigos from "../../Assets/ilustracaoTopoArtigos.jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer"
import ReactHtmlParser from "html-react-parser";

const Artigo = () => {
  const article = '';
  // useEffect(() => {
  //   setInfosArticle();
  // }, []);

  // const setInfosArticle = async () => {
  //   doGetArticle(dispatch, id).then((article) => {
  //     setArticle(article);
  //   });
  // };


  const primeiraParteHistoria = () => {
    if(article){
      return (
        <Container className={styles.generalContent}>
          <Row>
            <Col xs={12}>
              <div className={styles.banner}>
                <img
                  src={BannerArtigos}
                  alt="Banner de Artigos de Leonardo Araujo"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={2}></Col>
            <Col xs={12} md={8}>
              <div className={styles.generalContent}>
                <div className={styles.headerSection}>
                  <h1 className="titleSection">{article.title}</h1>
                </div>
              </div>
            </Col>
            <Col xs={12} md={2}></Col>
          </Row>
          <Row>
            <Col>
              <div className={styles.textoInteiro}>
               {ReactHtmlParser(article.text)}
              </div>
            </Col>
          </Row>
          <Row>
            <strong><a href="/artigos">Voltar</a></strong>
          </Row>
        </Container>
      );
    }else{
      return ''
    }
  };

  return (
    <>
      <Header />
      <div className="container">{primeiraParteHistoria()}</div>
      <Footer/>
    </>
  );
};

export default Artigo;
