import React from "react";
import styles from "./Apresentacao.module.css";
import LeonardoAraujo from "../../Assets/leoaraujo.jpg";
import { Container, Row, Col } from "react-bootstrap";
import BannerApresentacao from "../../Assets/ilustracao_1.jpg";

const Apresentacao = () => {
  return (
    <Container className={styles.generalContent}>
      <Row>
        <Col xs={12}>
          <div className={styles.banner}>
            <img
              src={BannerApresentacao}
              alt="Banner de Apresentação Leonardo Araujo"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={1}></Col>
        <Col xs={12} md={3}>
          <img className={styles.leonardoAraujo} src={LeonardoAraujo} alt="Leonardo Araujo" />
        </Col>
        <Col xs={12} md={7}>
          <div className={styles.boxText}>
            <p>
              Para competir com lucro, uma boa estratégia. Para ser diferente,
              um marketing inteligente e sensível. Para se antecipar ao mercado,
              proatividade. Para lucrar mais em novos tempos, inovação. Aqui
              mostro um pouco de minhas ideias, experiências e propostas de
              trabalho nesses temas.
            </p>
          </div>
        </Col>
        <Col xs={12} md={1}></Col>
      </Row>
    </Container>
  );
};

export default Apresentacao;
