import React from "react";
import styles from "./Palestras.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerPalestras from "../../Assets/ilustracaoTopoPalestras.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer"
import ReactHtmlParser from "html-react-parser";

const Palestra = () => {
  const speeche = ''
  // const [speeche, setSpeeche] = useState('');


  // useEffect(() => {
  //   setInfosArticle();
  // }, []);

  // const setInfosArticle = async () => {
  //   doGetSpeeche(dispatch, id).then((speeche) => {
  //       setSpeeche(speeche);
  //   });
  // };


  const primeiraParteHistoria = () => {
    if(speeche){
      return (
        <Container className={styles.generalContent}>
          <Row>
            <Col xs={12}>
              <div className={styles.banner}>
                <img
                  src={BannerPalestras}
                  alt="Banner de Palestras de Leonardo Araujo"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={2}></Col>
            <Col xs={12} md={8}>
              <div className={styles.generalContent}>
                <div className={styles.headerSection}>
                  <h1 className="titleSection">{speeche.title}</h1>
                </div>
              </div>
            </Col>
            <Col xs={12} md={2}></Col>
          </Row>
          <Row>
            <Col>
              <div className={styles.textoInteiro}>
               {ReactHtmlParser(speeche.text)}
              </div>
            </Col>
          </Row>
          <Row>
            <strong><a href="/">Voltar</a></strong>
          </Row>
        </Container>
      );
    }else{
      return ''
    }
  };

  return (
    <>
      <Header />
      <div className="container">{primeiraParteHistoria()}</div>
      <Footer/>
    </>
  );
};

export default Palestra;
