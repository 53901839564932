import React from 'react';


const Button = ({ children, ...props }) => {
  return (
    <button {...props} className="btn btn-dark btn-lg">
      {children}
    </button>
  );
};

export default Button;
