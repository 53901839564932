import React from "react";
import Header from "../Header/Header";
import Apresentacao from "../Apresentacao/Apresentacao";
import Depoimentos from "../Depoimentos/Depoimentos";
import MinhaHistoria from "../MinhaHistoria/MinhaHistoria";
import Consultoria from "../Consultoria/Consultoria";
import Palestras from "../Palestras/Palestras";
import Conteudos from "../Conteudos/Conteudos";
import Contato from "../Contato/Contato";

const Home = () => {
  return (
    <>
      <Header />
      <div className="container">
        <Apresentacao/>
        <Depoimentos/>
        <MinhaHistoria/>
        <Consultoria/>
        <Palestras/>
        <Conteudos/>
        <Contato/>
      </div>
    </>
  );
};

export default Home;
