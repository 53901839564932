import React, { useState } from "react";
import styles from "./Contato.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerContato from "../../Assets/ilustracaoTopoContato.jpg";
import Modal from "@mui/material/Modal";
import Input from "../Forms/Input/Input";

const Contato = () => {
  const [openModal, setOpenModal] = useState(false);
  const [remetente, setRemetente] = useState('');
  const [assunto, setAssunto] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [sendSuccess, setSendSuccess] = useState(false)

  const handleOpenModalContato = () => {
    setOpenModal(true);
  };
  const handleCloseModalContato = () => {
    setOpenModal(false);
  };

  const sendEmail = () => {
    setSendSuccess(true)
  }
  const handleModalContato = () => {
    return (
      <div>
        <Modal open={openModal} onClose={handleCloseModalContato}>
          <div className={styles.modal}>
          <h2>Entre em contato comigo!</h2>
          <Input
            label="Email"
            type="text"
            name="remetente"
            required="required"
            onChange={({target}) => setRemetente(target.value)}
            value={remetente}
          />
          <Input
            label="Assunto"
            type="text"
            name="assunto"
            required="required"
            onChange={({target}) => setAssunto(target.value)}
            value={assunto}
          />
          <Input
            label="Mensagem"
            type="text"
            name="mensagem"
            required="required"
            onChange={({target}) => setMensagem(target.value)}
            value={mensagem}
          />
          <button className={styles.botaoFaleComigo} onClick={sendEmail}>ENVIAR</button>
          {sendSuccess && <p>Mensagem enviada com sucesso</p>}
          <p><strong>www.leonardoaraujo.net</strong></p>
          </div>
        </Modal>
      </div>
    );
  };
  const contentContato = () => {
    return(
      <Container className={styles.generalContent} id="Contato">
      <Row>
        <Col xs={12}>
          <div className={styles.banner}>
            <img src={BannerContato} alt="Banner Contato de Leonardo Araujo" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={1}></Col>
        <Col xs={12} md={10}>
          <div className={styles.generalContent}>
            <div className={styles.headerSection}>
              <h1 className="titleSection">CONTATO</h1>
              <div className={styles.email}>
                <a
                  href="mailto:contato@leonardoaraujo.net"
                  rel="noreferrer"
                  target="_blank"
                >
                  CONTATO@LEONARDOARAUJO.NET
                </a>
              </div>
              <p className="subTitleSection">
                Vamos conversar sobre consultorias e palestras que podem ajudar
                a sua empresa a ser mais proativa e inovadora.
              </p>
            </div>
            <div className={styles.botaoContato}>
              <button className={styles.botaoFaleComigo} onClick={handleOpenModalContato}>FALE COMIGO</button>
            </div>
          </div>
        </Col>
        <Col xs={12} md={1}></Col>
      </Row>
    </Container>
    )
  }
  return (
    <>
    {contentContato()}
    {openModal && handleModalContato()}
    </>
    
  );
};

export default Contato;
