import React from "react";
import styles from "./Footer.module.css";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="container">
      <Container className={styles.generalContent}>
        <Row>
          <Col xs={12}>
            <div className={styles.section}>
              <h1 className="titleRodape">FALE COMIGO</h1>
              <div className={styles.email}>
                <a
                  href="mailto:contato@leonardoaraujo.net"
                  rel="noreferrer"
                  target="_blank"
                >
                  CONTATO@LEONARDOARAUJO.NET
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
