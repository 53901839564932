import React, { useState, useEffect } from "react";
import styles from "./Artigos.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerArtigos from "../../Assets/ilustracaoTopoArtigos.jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { doGetAllArticles } from "../../Infrastructure/Actions/Article";
import { Store } from "../../Infrastructure/Store/Store";

const redirectIndex = () => {
  window.location.href = "/";
};

const redirectProatividade = () => {
  window.open("http://proatividademercado.com.br/site/blog/", "_blank");
};
const redirectArtigosLinkedin = () => {
  window.open(
    "https://www.linkedin.com/in/araujo-leonardo/?originalSubdomain=br",
    "_blank"
  );
};
const Artigos = () => {
  const { state, dispatch } = React.useContext(Store);
  const [articles, setArticles] = useState("");

  useEffect(() => {
    setAllArticles();
  }, []);

  const setAllArticles = async () => {
    await doGetAllArticles(dispatch).then((article) => {
      setArticles(article);
    });
  };

  const formatDate = (date) => {
    let dateString = date.toString();
    let withoutHours = dateString.split("T");
    let rigthDate = withoutHours[0].split("-");
    let currentDate = `${
      rigthDate[2] + "/" + rigthDate[1] + "/" + rigthDate[0]
    }`;
    return currentDate;
  };

  const primeiraParteHistoria = () => {
    return (
      <Container className={styles.generalContent}>
        <Row>
          <Col xs={12}>
            <div className={styles.banner}>
              <img
                src={BannerArtigos}
                alt="Banner de Artigos de Leonardo Araujo"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}></Col>
          <Col xs={12} md={8}>
            <div className={styles.generalContent}>
              <div className={styles.headerSection}>
                <h1 className="titleSection">ARTIGOS</h1>
              </div>
            </div>
          </Col>
          <Col xs={12} md={2}></Col>
        </Row>
        {articles
          ? articles.map((article) => {
              return (
                <Row>
                  <Col xs={12} md={12}>
                    <div className={styles.contentArticle}>
                      <p className={styles.titleArticle}>
                        {article.title}
                      </p>
                      <p>{formatDate(article.date)}</p>
                      <button className={styles.botaoArtigo}><a href={`artigo/${article.id}`}>Ler artigo</a></button>
                    </div>
                  </Col>
                </Row>
              );
            })
          : ""}

        <Row>
          <Col>
            <div style={{ marginTop: "1em" }}>
              <strong>
                <a onClick={redirectIndex} style={{ cursor: "pointer" }}>
                  Voltar
                </a>
              </strong>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.botoesRodapeArtigos}>
              <button
                className={styles.botaoArtigoEBlog}
                onClick={redirectProatividade}
              >
                Blog Proatividade
              </button>
              <button
                className={styles.botaoArtigoEBlog}
                onClick={redirectArtigosLinkedin}
              >
                Artigos Linkedin
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  const redirectLivros = () => {
    window.location.href = "/poesia-livros";
  };
  return (
    <>
      <Header />
      <div className="container">{primeiraParteHistoria()}</div>
      <Footer />
    </>
  );
};

export default Artigos;
