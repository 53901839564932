import React from "react";
import styles from "./Imprensa.module.css";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactHtmlParser from "html-react-parser";

const Imprensa = (props) => {
  const notices = []
  // const { dispatch } = React.useContext(Store);
  // const [notices, setNotices] = useState([]);

  // useEffect(() => {
  //   setAllNotices();
  // }, []);

  // const setAllNotices = async () => {
  //   await doGetAllNotices(dispatch).then((notice) => {
  //     setNotices(notice);
  //   });
  // };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const formatDate = (date) => {
    let dateString = date.toString();
    let withoutHours = dateString.split("T");
    let rigthDate = withoutHours[0].split("-");
    let currentDate = `${
      rigthDate[2] + "/" + rigthDate[1] + "/" + rigthDate[0]
    }`;
    return currentDate;
  };

  return (
    <Container id="Palestras">
      <Row>
        <Col xs={12} md={1}></Col>
        <Col xs={12} md={10}>
          <div className={styles.generalContent}>
            <div className={styles.headerSection}>
              <h1 className="titleSection">IMPRENSA</h1>
            </div>
          </div>
        </Col>
        <Col xs={12} md={1}></Col>
      </Row>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        customTransition="all 0.5s"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {notices
          ? notices.map((notice) => {
              return (
                <div className={styles.sliderPalestras}>
                  <h3>{notice.title}</h3>
                  <div className={styles.infos}>
                    <p className={styles.date}>{formatDate(notice.date)}</p>
                    {notice.link ? (
                      <a
                        href={notice.link}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.saibaMais}
                      >
                        VER MAIS
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  {ReactHtmlParser(notice.text)}
                </div>
              );
            })
          : ""}
      </Carousel>
    </Container>
  );
};

export default Imprensa;
