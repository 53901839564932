import React from "react";
import styles from "./IncidentesEAlquimias.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerPoesia from "../../Assets/ilustracaoTopoPoesia.jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const IncidentesEAlquimias = () => {
  const primeiraParteHistoria = () => {
    return (
      <>
        <Container className={styles.generalContent}>
          <Row>
            <Col xs={12}>
              <div className={styles.banner}>
                <img
                  src={BannerPoesia}
                  alt="Banner de Poesia de Leonardo Araujo"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12}>
              <div className={styles.generalContent}>
                <div className={styles.headerSection}>
                  <h1 className="titleSection">INCIDENTES E ALQUIMIAS</h1>
                  <p className="subTitleSection">Seleção de poemas</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>CANTO DE ENTRADA</div>
                <div className={styles.textoPoema}>
                  <p>
                    Não lavro palavras em vão.
                    <br />
                    O verbo que cresce no peito
                    <br />
                    é sol ganhando o corpo,
                    <br />
                    pássaro arranhando as entranhas.
                    <br />
                    <br />
                    Não deito esse gesto em vão.
                    <br />
                    O punho que desenha o poema
                    <br />
                    é lâmina rasgando o silêncio,
                    <br />
                    ave em estado de canto e dor.
                    <br />
                    <br />
                    Evoco palavras que cortam
                    <br />
                    ao feitio de foice
                    <br />
                    e perfilam os calos da mão.
                    <br />
                    <br />
                    Palavras sombrias,
                    <br />
                    mas sempre decifráveis
                    <br />
                    como frutos e estações.
                    <br />
                    <br />
                    Palavras de esperança
                    <br />
                    que em meu peito arquivo
                    <br />
                    como quem guarda um vendaval.
                    <br />
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}></Col>
          </Row>
          <Row>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>
                  AS METÁFORAS DA ARTE POÉTICA
                </div>
                <div className={styles.textoPoema}>
                  <p>
                    Lapidar palavras
                    <br />
                    não é sina fácil
                    <br />
                    como ensinar ao fogo
                    <br />
                    o segredo da centelha.
                    <br />
                    <br />
                    Mais que difícil sina,
                    <br />
                    é façanha e magia,
                    <br />
                    como extrair do galope
                    <br />
                    ou do próprio silêncio
                    <br />
                    alguma melodia.
                    <br />
                    <br />
                    Mais que façanha,
                    <br />
                    é sempre ofício,
                    <br />
                    como o do vento
                    <br />
                    em seu embalo
                    <br />é mover moinhos.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}></Col>
          </Row>
          <Row>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>MINAS UNIVERSAL</div>
                <div className={styles.textoPoema}>
                  <p>
                    Mar não há. <br />
                    Por isso sonhamos <br />
                    acreditamos oceânicos
                    <br />
                    os rios que nos embalam
                    <br />
                    mesmo os que vão
                    <br />
                    morrendo
                    <br />
                    em seu itinerário
                    <br />
                    e renascem do nada.
                    <br />
                    Há o sertão.
                    <br />
                    Por isso amamos
                    <br />
                    tudo o que é vereda
                    <br />
                    reiventando no mapa
                    <br />
                    encantados destinos:
                    <br />
                    dia a dia
                    <br />
                    diadorim
                    <br />
                    rio a rio
                    <br />
                    riobaldo.
                    <br />
                    Há a linguagem.
                    <br />
                    Por isso cantamos
                    <br />
                    inventamos contos
                    <br />
                    lapidamos poemas
                    <br />
                    traduzimos a paisagem
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>EXERCÍCIO DE PAZ</div>
                <div className={styles.textoPoema}>
                  <p>
                    Venho de branco para dizer:
                    <br />
                    <br />
                    o cactos, acre e bela flor do sol,
                    <br />
                    guarda seiva que nos provém a sede;
                    <br />
                    em caminhos de fogo e pedras
                    <br />
                    há sementes, presságio de pétalas;
                    <br />
                    do punhal (além do golpe) extrai-se o brilho,
                    <br />
                    luz de lâmina que nos ilumina a face;
                    <br />
                    dentro da bomba há sempre uma ave,
                    <br />
                    ilesa à explosão, em silencio a nos mirar.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}></Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>TRÍADE</div>
                <div className={styles.textoPoema}>
                  <p>
                    O amor, a morte, o sonho:
                    <br />
                    pontos de pura fantasia no diamante,
                    <br />
                    vértices do mais frágil triângulo.
                    <br />
                    Não há geometria que mais encante
                    <br />
                    quando se une um vértice a outro
                    <br />
                    - e o amor, em sonho, não morre.
                    <br />
                    De tão belo e leve este traçado,
                    <br />
                    faz lembrar a dança dos trapézios
                    <br />
                    - e o sonho, por um triz, ao amor sobrevive.
                    <br />
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}></Col>
          </Row>
          <Row>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>NÃO MORRER</div>
                <div className={styles.textoPoema}>
                  <p>
                    Todos os dias temo a morte
                    <br />
                    (a sombra que serei, enfim)
                    <br />
                    mas não coleciono remédios.
                    <br />
                    por menores e poucos que sejam,
                    <br />
                    meus pertences pulsam, não morrerão.
                    <br />
                    Minhas mágoas são névoas
                    <br />e duram menos que uma chuva.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}></Col>
          </Row>
          <Row>
            <Col>
              <div className={styles.generalContent}>
                <strong>
                  <a onClick={redirectPoesiaLivros} style={{cursor:'pointer'}}>Voltar</a>
                </strong>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const redirectPoesiaLivros = () => {
    window.location.href = `/poesia-livros`;
  };

  return (
    <>
      <Header />
      <div className="container">{primeiraParteHistoria()}</div>
      <Footer />
    </>
  );
};

export default IncidentesEAlquimias;
