import React from "react";
import styles from "./NoTapa.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerPoesia from "../../Assets/ilustracaoTopoPoesia.jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const NoTapa = () => {
  const primeiraParteHistoria = () => {
    return (
      <>
        <Container className={styles.generalContent}>
          <Row>
            <Col xs={12}>
              <div className={styles.banner}>
                <img
                  src={BannerPoesia}
                  alt="Banner de Poesia de Leonardo Araujo"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12}>
              <div className={styles.generalContent}>
                <div className={styles.headerSection}>
                  <h1 className="titleSection">NO TAPA</h1>
                  <p className="subTitleSection">Seleção de poemas</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>SÍNCOPE</div>
                <div className={styles.textoPoema}>
                  <p>
                    Sob esta tarde que ameaça cair
                    <br />
                    caminho em paz, consciente dos meus pecados.
                    <br />
                    Noventa e seis quilos mais pesado que o ar,
                    <br />
                    sigo impune, vão comigo os meus sonhos.
                    <br />
                    Na mão o livro ainda por ler, óculos no bolso,
                    <br />
                    algumas ideias na cabeça, três chamadas no celular
                    <br />
                    (não atendidas por livre e espontânea vontade), <br />
                    nesta tarde que, de súbito, desabou por aqui
                    <br />e me deixou com essa vontade tola de sumir do mapa!
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}></Col>
          </Row>
          <Row>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>TEMPO</div>
                <div className={styles.textoPoema}>
                  <p>
                    Muito tempo, milhões de minutos
                    <br />
                    (mas parece que é algo súbito),
                    <br />
                    quando o espelho denuncia ao outro
                    <br />
                    o que este não quer ver, encarar.
                    <br />
                    <br />
                    Rompe o dia, começa o rito, rotina.
                    <br />
                    Como sempre, o espelho está lá, inerte.
                    <br />
                    O outro, ao ver-se em desalinho, espanta-se:
                    <br />
                    “estou ficando velho ou é falta de prumo?”
                    <br />
                    <br />
                    O espelho nada responde, só mostra.
                    <br />
                    O outro pensa, olha, reflete, mira.
                    <br />
                    Sete da manhã, o outro infere confiante:
                    <br />
                    “nada melhor que um bom banho!”.
                    <br />
                    <br />
                    O espelho, como se sabe, não fala,
                    <br />
                    nunca emite opinião, apenas mostra,
                    <br />
                    mas de tanto evidenciar, quase pensa:
                    <br />
                    “amanhã é outro dia, que venha!”
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}></Col>
          </Row>
          <Row>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>ANO NOVO</div>
                <div className={styles.textoPoema}>
                  <p>
                    Como este que agora finda, areia que se esvai,
                    <br />
                    o ano novo também terá mais de trezentos dias
                    <br />
                    e provavelmente veremos as mesmas coisas:
                    <br />
                    algumas belas, outras sem brilho ou quase banais, incidentes
                    ordinários, fatos repetidos em manchetes.
                    <br />
                    <br />
                    Ainda que nada se renove, claro efeito déjà vu, <br />
                    a mesma folha, igual textura, o mesmo caule,
                    <br />
                    a mesmíssima cor, enfim, a mesma espécie de flor, o que
                    ninguém nos rouba é a forma de ver, apreciar:
                    <br />
                    se quisermos bela, será; ou feia, se assim deixamos
                    impor-se.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>
                  DE PINOT A CABERNET, APRENDER
                  <p>(Livre versão para Expertise)</p>
                </div>

                <div className={styles.textoPoema}>
                  <p>
                    Um dia qualquer alguém perguntou:
                    <br />
                    - É difícil fazer vinho? <br />
                    Com elegância, Baronesa Rothschild respondeu:
                    <br />
                    - Produzir vinhos é relativamente simples, <br />
                    só os primeiros duzentos anos são difíceis.
                    <br />
                    <br />
                    Saber matizes da água e do vinho
                    <br />
                    não exige domínio nem tempo: basta ver.
                    <br />
                    Aprender além dos olhos requer bem mais.
                    <br />
                    Tudo é somatório, muitas ciências. <br />
                    Perceber nuances de castas: assim é viver.
                    <br />
                    <br />
                    Como também se pode afirmar
                    <br />
                    que um poema, por mais fácil e fluído
                    <br />
                    (parto natural), não é obra do acaso.
                    <br />
                    Tempo nos ombros, muita vida, anos a fio.
                    <br />
                    Depois, basta talhar, no tapa, lutar sempre.
                    <br />
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}></Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>NOTAS DE VIAGEM 2</div>
                <div className={styles.textoPoema}>
                  <p>
                    Oito de outubro, oito e oito da noite.
                    <br />
                    Na esquina da Calle Calatrava existo, logo sinto:
                    <br />
                    este número, em duplo redondo, sugere encontro.
                    <br />
                    Oito fôlegos (sete mais um) cabem em meu peito?
                    <br />
                    Se pudesse congelaria o tempo nas ampulhetas.
                    <br />
                    Nesta espera, não quero relógios ou cronômetros
                    <br />
                    - todos tão lógicos em sua mecânica!
                    <br />
                    Prefiro ampulhetas, redondas em oito,
                    <br />
                    quase irreais, por onde a vida corre,
                    <br />
                    imprecisa.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}></Col>
          </Row>
          <Row>
            <Col xs={12} md={4}></Col>
            <Col xs={12} md={4}>
              <div className={styles.poemasEsquerda}>
                <div className={styles.tituloDoPoema}>AMAR, AMOR</div>
                <div className={styles.textoPoema}>
                  <p>
                    Custa muito, os olhos da cara,
                    <br />
                    vencer todo dia no jogo do amor
                    <br />
                    - que, a priori, foi feito para empate.
                    <br />
                    <br />
                    Dura muito, quase uma vida inteira, <br />
                    aprender que o amor não se explica, <br />
                    - que, na praxis, é como se ama, no embate.
                    <br />
                    <br />
                    Pesa muito, um fardo de lenha,
                    <br />
                    suportar silêncios, culpar, obrar pecados
                    <br />
                    - que, via-crucis, faz parte do romance, cedo ou tarde.
                    <br />
                    <br />
                    Vale muito, uma trama bem urdida,
                    <br />
                    acolher-se no outro, ter o mesmo sorriso, <br />
                    - que, vis-à-vis, é como ser cúmplice da outra parte.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}></Col>
          </Row>
          <Row>
            <Col>
              <div className={styles.generalContent}>
                <strong>
                  <a
                    onClick={redirectPoesiaLivros}
                    style={{ cursor: "pointer" }}
                  >
                    Voltar
                  </a>
                </strong>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const redirectPoesiaLivros = () => {
    window.location.href = `/poesia-livros`;
  };

  return (
    <>
      <Header />
      <div className="container">{primeiraParteHistoria()}</div>
      <Footer />
    </>
  );
};

export default NoTapa;
