import React, { useState } from "react";
import styles from "./MinhaHistoria.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerMinhaHistoria from "../../Assets/ilustracaoMinhaHistoria.png";
import Vermais from "../../Assets/verMais.jpg";
import Vermenos from "../../Assets/verMenos.jpg";
import fotoLeonardo from "../../Assets/fotoLeonardo.jpg";
import Leonardo from "../../Assets/Leonardo.jpg";
import Imprensa from "../Imprensa/Imprensa";

const MinhaHistoria = () => {
  const [abrirSegundaParte, setAbrirSegundaParte] = useState(false);

  const primeiraParteHistoria = () => {
    return (
      <Container className={styles.generalContent} id="MinhaHistoria">
        <Row>
          <Col xs={12}>
            <div className={styles.banner}>
              <img
                src={BannerMinhaHistoria}
                alt="Banner da História de Leonardo Araujo"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}></Col>
          <Col xs={12} md={8}>
            <div className={styles.generalContent}>
              <div className={styles.headerSection}>
                <h1 className="titleSection">MINHA HISTÓRIA</h1>
              </div>
              <div className={styles.boxText}>
                <p>
                  Marketing | Inovação | Estratégia. Essa trinca faz parte da
                  minha história profissional. São blocos de um lego que venho
                  combinando em meu trabalho ao longo dos anos.
                </p>
                <p>
                  Meu primeiro plano de marketing nasceu em 1981 e tinha 144
                  páginas. Fico imaginando essa robustez toda em plena era da
                  internet das coisas. Antes da décima página, os clientes já
                  mudaram as perguntas, o mercado virou, tudo é novo. E o plano
                  já era.
                </p>
              </div>
              <div className={styles.verMais}>
                <img
                  src={!abrirSegundaParte ? Vermais : Vermenos}
                  alt="Ver mais da História de Leonardo Araujo"
                  style={{ cursor: "pointer" }}
                  onClick={() => {setAbrirSegundaParte(!abrirSegundaParte)}}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} md={2}></Col>
        </Row>
      </Container>
    );
  };

  const segundaParteHistoria = () => {
    return (
      <>
      <Container className={styles.generalContent}>
        <Row>
          <Col xs={12} md={6}>
            <div className={styles.fotoLeoEsquerda}>
              <img src={fotoLeonardo} alt="Leonardo Araujo" />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className={styles.banner}>
              <p>
                Mas continuo batendo nas teclas do marketing. Hoje busco outros
                tons, e a música vai mudando sempre. Nesse turbilhão de bits da
                revolução digital lidar com marketing é uma reinvenção diária.{" "}
                <br />
                <br />
                O interesse pela inovação nasceu da minha pesquisa de mestrado
                no tema desenvolvimento de novos produtos. O convívio
                estimulante com estratégia vem da prática executiva e se ampliou
                com as consultorias e pesquisas no campo da proatividade: como
                as empresas podem antecipar mudanças no mercado? <br />
                <br />
                Tudo se complementa de forma sinérgica: a proatividade no
                marketing alavanca a inovação, e vice-versa. Da mesma
                forma,estratégias proativas de mercado ganham força pela ousadia
                em inovação e pela inteligência e sensibilidade no marketing.
              </p>
              <div
                className={styles.citacao}
                style={{ marginTop: "2em", marginBottom: "2em" }}
              >
                <p>
                  O que nos torna relevantes naquilo que
                  <br />
                  fazemos é a paixão. Depois, vem muito trabalho
                </p>
              </div>
              <div>
                <p>
                  Vale a pena suar a camisa por aquilo que nos dá brilho nos
                  olhos. “Eu nunca fiz a volta perfeita. ” Ayrton Senna me
                  provoca todos os dias com essa frase.{" "}
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}></Col>
          <Col xs={12} md={8}>
            <div className={styles.divisaoHistoria}>
              <p>
                Em minhas voltas profissionais quero destacar duas “escolas”
                extraordinárias.<br/><br/>
                
                A primeira foi a Localiza. Se existe um único
                verbo para encarnar o DNA Localiza é esse: mudar. Foram muitos
                anos e nenhum igual ao outro: passei por diferentes funções e
                operações, participei da criação de novos modelos de negócio.
              </p>
            </div>
          </Col>
          <Col xs={12} md={2}></Col>
        </Row>
      </Container>
      <Container>
        <Row>
        <Col xs={12} md={6}>
            <div className={styles.fotoLeoEsquerda}>
              <img src={Leonardo} alt="Leonardo Araujo" />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className={styles.parteDeBaixo}>
            <div
                className={styles.citacao}
                style={{ marginTop: "2em", marginBottom: "2em" }}
              >
                <p>
                Aprendi a mudar, mudando. Gestão é isso.<br/>O mais são páginas de livros</p>
              </div>
              <div>
              <p style={{lineHeight: '23px'}}>
                Depois da “escola” Localiza, uma escola de negócios com padrão de excelência. Na Fundação Dom Cabral, meu primeiro desafio foi executivo. De rent a car para business school, aprendi novas lições de marketing e de estratégia. Atualmente, como professor associado, tenho muito orgulho sempre que sou convidado para atuar em vários programas relevantes da FDC.<br/><br/>
                
                Meus projetos profissionais incluem também consultorias e palestras em marketing, inovação e estratégia. Sobre esses temas tenho experiências e ferramentas para compartilhar com as empresas e muita disposição para descobrir sempre mais.<br/><br/>
                
                Nenhuma história profissional é completa sem a família, os amigos do peito e outras paixões que nos animam e elevam. A poesia é uma dessas paixões em minha história. Uma via que me leva mais longe. </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
          <div className={styles.botaoPoesia}>
            <button className={styles.botaoTextPoesia} onClick={redirectPage}>POESIA</button>
            </div>
          </Col>
        </Row>
        <Row>
          <Imprensa/>
        </Row>
      </Container>
      </>
    );
  };

  const redirectPage = () =>{
    window.location.href = `/poesia`;
  }

  return (
    <>
      {primeiraParteHistoria()}
      {abrirSegundaParte && segundaParteHistoria()}
    </>
  );
};

export default MinhaHistoria;
