import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

import axios from "axios";
import { storeToken, getToken } from "../../services/auth";
import Button from "../Forms/CustomButton/CustomButton";
import CustomSnackbar from "../../GeneralComponents/Snackbar";
import { Link } from "react-router-dom";
import Input from "../Forms/Input/Input";
import styles from "./LoginForm.module.css";

const LoginForm = () => {
  const [tokenGuardado, setTokenGuardado] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [abreNotificacao, setAbreNotificacao] = useState(false);
  const [resultadoRequisicao, setResultadoRequisicao] = useState("success");
  const [mensagemDeResultado, setMensagemDeResultado] = useState("");

  useEffect(() => {
    getToken() && setTokenGuardado(true);
  }, []);

  function isValidForm() {
    var isValid = true;
    if (email.length == 0) {
      setErrorEmail("Digite um e-mail");
      isValid = false;
    }
    if (password.length == 0) {
      setErrorPassword("Digite uma senha");
      isValid = false;
    }
    return isValid;
  }

  async function login() {
    if (!isValidForm()) return;
    const account = {
      email: email,
      password: password,
    };

    await axios
      .post(process.env.REACT_APP_API_URL + "/api/auth/login", account)
      .then((res) => {
        storeToken(res.data.token);
        setTokenGuardado(true);
        abrirNotificacao(res.data.message, "success");
      })
      .catch((error) => {
        let message = error.response.data.message;
        abrirNotificacao(message, "error");
      });
  }

  function fecharNotificacao() {
    setAbreNotificacao(false);
  }

  function abrirNotificacao(mensagemDeResultado, resultadoRequisicao) {
    setAbreNotificacao(true);
    setResultadoRequisicao(resultadoRequisicao);
    setMensagemDeResultado(mensagemDeResultado);
  }

  return tokenGuardado ? (
    <Navigate to="/dashboard" />
  ) : (
    <React.Fragment>
      <CustomSnackbar
        abreNotificacao={abreNotificacao}
        fecharNotificacao={fecharNotificacao}
        mensagemDeResultado={mensagemDeResultado}
        resultadoRequisicao={resultadoRequisicao}
      />
      <div className="container">
        <div className="outer" style={{ margin: '8em auto', alignItems:"center" }}>
          <div className="inner">
            <section className="animeLeft">
              <div className={styles.headerLogo}>
                <h1>Login</h1>
              </div>
              <form className={styles.form}>
                <Input
                  label="Email"
                  type="text"
                  name="email"
                  onChange={({ target }) => {
                    setEmail(target.value);
                    setErrorEmail("");
                  }}
                />
                <span style={{ color: "#ff0000" }}>{errorEmail}</span>

                <Input
                  label="Senha"
                  type="password"
                  name="password"
                  onChange={({ target }) => {
                    setPassword(target.value);
                    setErrorPassword("");
                  }}
                />
                <span style={{ color: "#ff0000" }}>{errorPassword}</span>
              </form>

              <Button onClick={login}>Fazer Login</Button>
              
              <p style={{ margin: "1em 0 1em 0" }}></p>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginForm;
