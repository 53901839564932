import './App.css';
import './bootstrap.css';
import Routes from '../src/routes/routes'

function App() {
  return (
    <Routes/>
  );
}

export default App;
