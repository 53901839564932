import api from "../../services/api";

export const repoGetAllArticles = () => {
  return new Promise((resolve) => {
    let response = null;
    api
      .get("/article")
      .then(async (res) => {
        resolve(res.data.value);
        return res.data.value;
      })
      .catch((error) => {
        response = error.response.data;
      })
      .finally(() => {
        resolve(response);
        return response;
      });
  });
};

export const repoGetArticle = (articleId) => {
  return new Promise((resolve) => {
    let response = null;
    api
      .get(`/article/${articleId}`)
      .then(async (res) => {
        resolve(res.data.value);
        return res.data.value;
      })
      .catch((error) => {
        response = error.response.data;
      })
      .finally(() => {
        resolve(response);
        return response;
      });
  });
};

export const repoCreateArticle = (articleInfos) => {
  return new Promise(async (resolve, reject) => {
    let response = null;
    await api
      .post("/article/", articleInfos)
      .then(async (res) => {
        resolve(res);
        return res;
      })
      .catch((error) => {
        response = error.response.data;
      })
      .finally(() => {
        resolve(response);
        return response;
      });
  });
};

export const repoDeleteArticle = (articleId) => {
  return new Promise((resolve) => {
    let response = null;
    api
      .delete(`/article/${articleId}`)
      .then(async (res) => {
        resolve(res);
        return res;
      })
      .catch((error) => {
        response = error.response.data;
      })
      .finally(() => {
        resolve(response);
        return response;
      });
  });
};
