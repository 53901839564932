import React from "react";
import styles from "./PoesiaLivros.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerPoesia from "../../Assets/ilustracaoTopoPoesia.jpg";
import IncidentesEAlquimias from "../../Assets/livro_incidentes_e_alquimias.jpg";
import NoTapa from "../../Assets/livro_no_tapa.jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const PoesiaLivros = () => {
  const primeiraParteHistoria = () => {
    return (
      <Container className={styles.generalContent}>
        <Row>
          <Col xs={12}>
            <div className={styles.banner}>
              <img
                src={BannerPoesia}
                alt="Banner de Poesia de Leonardo Araujo"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className={styles.generalContent}>
              <div className={styles.headerSection}>
                <h1 className="titleSection">LIVROS DE POESIA</h1>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={3}>
            <img
              width="222"
              height="332"
              src={IncidentesEAlquimias}
              alt="Incidentes e Alquimias Leonardo Araujo"
              style={{ margin: "0 auto" }}
            />
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.tituloLivro}>
              <p>
                <strong>Incidentes e alquimias</strong>
              </p>
            </div>
            <div className={styles.textoLivro}>
              <p>A obra reúne poemas de dois livros: Aprendiz de Alquimia</p>
              <p>
                (Prêmio Nacional Emilio Moura, 1984) e Incidentes, uma coletânea
                de poemas escritos entre 1985 e 1990.
              </p>
            </div>
            <div className={styles.trechoLivro}>
              <p>
                Há muito,
                <br />
                procuram a pedra filosofal
                <br />
                que tudo em ouro transformaria
                <br />
                - o que é sonho ou alquimia.
                <br />
                Desde sempre,
                <br />
                cada instante é sem igual,
                <br />
                tudo o que há não permanece, varia
                <br />- o que é vida ou filosofia.
              </p>
            </div>
          </Col>
          <Col xs={12} md={3}></Col>
          <Col xs={12}>
            <div className={styles.botaoPoemas}>
              <button className={styles.botaoTextPoemas} onClick={redirectIncidentesEAlquimias}>LER POEMAS</button>
            </div>
          </Col>
        </Row>
        <Row className={styles.linha}>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={3}>
            <img
              width="222"
              height="332"
              src={NoTapa}
              alt="no Tapa Leonardo Araujo"
              style={{ margin: "2em auto" }}
            />
          </Col>
          <Col xs={12} md={3}>
            <div className={styles.tituloLivro} style={{ marginTop: "2em" }}>
              <p>
                <strong>No Tapa</strong>
              </p>
            </div>
            <div className={styles.textoLivro}>
              <p>
                Com poemas escritos em sua maioria em 2009, esse livro traz
                reflexões (ou tapas) sobre a vida, além de ensaios sobre o amor,
                notas de viagem e outras experiências.
              </p>
            </div>
            <div className={styles.trechoLivro}>
              <p>
                O que é exatamente um tapa?
                <br />
                Recorra-se ao léxico, sabedoria:
                <br />
                “substantivo masculino e feminino,
                <br />
                pancada, tragada, argumento
                <br />
                irrespondível.”
                <br />
                Um tapa, portanto, é preto e branco, homem e mulher, gesto que
                fala e cala.
              </p>
            </div>
          </Col>
          <Col xs={12} md={3}></Col>
          <Col xs={12}>
            <div className={styles.botaoPoemas}>
              <button className={styles.botaoTextPoemas} onClick={redirectNoTapa}>LER POEMAS</button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  const redirectIncidentesEAlquimias = () => {
    window.location.href = `/incidentes-e-alquimias`;
  }
  const redirectNoTapa = () => {
    window.location.href = `/no-tapa`;
  }
  return (
    <>
      <Header />
      <div className="container">{primeiraParteHistoria()}</div>
      <Footer />
    </>
  );
};

export default PoesiaLivros;
