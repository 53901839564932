import React from "react";
import styles from "./Depoimentos.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const Depoimentos = () => {
  return (
    <Container className={styles.generalContent}>
      <Row>
        <Col xs={12}></Col>
        <Col xs={12}>
          <Slide easing="ease">
            <div className="each-slide">
              <div>
                <p>
                  "O Prof. Leonardo Ara&uacute;jo vem de uma carreira de sucesso
                  como executivo e leva essa experi&ecirc;ncia para suas aulas.
                  Participou com excel&ecirc;ncia de treinamentos para
                  executivos da FCA - Fiat Chrysler Automobiles. Como
                  pesquisador, seu trabalho no campo da proatividade de mercado
                  marcou um aspecto diferenciador das empresas vencedoras. Seu
                  livro Empresas Proativas deve fazer parte da biblioteca de
                  quem deseja se destacar no mercado&rdquo;.
                </p>
                <p>
                  – CARLOS EUGÊNIO DUTRA, Diretor de Planejamento e Estratégia
                  de Produto da Fiat Chrysler Automobiles
                </p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;Tive oportunidade de atuar como Conselheiro, em
                  importante empresa do setor qu&iacute;mico do Sul do Brasil,
                  ao lado do tamb&eacute;m Conselheiro Leonardo Ara&uacute;jo.
                  Suas contribui&ccedil;&otilde;es sempre foram de extrema
                  import&acirc;ncia para a estrat&eacute;gia e gest&atilde;o
                  daquela empresa&rdquo;.
                </p>
                <p>
                  – EDUARDO VALÉRIO, Diretor Presidente da J. Valério Gestão da
                  Empresa Familiar
                </p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;No mundo atual, proatividade e inova&ccedil;&atilde;o
                  s&atilde;o pr&aacute;ticas fundamentais para garantir a
                  sobreviv&ecirc;ncia das empresas no longo prazo. O Prof. Leo,
                  um grande conhecedor do assunto, gera muito valor com suas
                  ideias e solu&ccedil;&otilde;es. &rdquo;
                </p>
                <p>
                  – ERNESTO G. VILELA, Fundador e CEO da ENOX On-Life Network
                </p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;Conhe&ccedil;o o Leonardo Ara&uacute;jo de longa data.
                  Leo foi um dos respons&aacute;veis pelo pioneirismo da
                  Localiza no neg&oacute;cio de aluguel de frotas no Brasil. Com
                  sua vis&atilde;o de marketing e comunica&ccedil;&atilde;o,
                  contribuiu para nos posicionar como um importante player nesse
                  segmento. Leo sempre empreendeu o novo: sua pesquisa sobre
                  empresas proativas mereceu nosso aplauso e foi tema de uma
                  brilhante palestra em nossa conven&ccedil;&atilde;o de 2012.
                  Sou um entusiasta do seu trabalho. &rdquo;
                </p>
                <p>– EUGÊNIO MATTAR, CEO Localiza</p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;N&atilde;o existe profissional completo. Existem
                  profissionais abrangentes. &Eacute; o caso do Prof. Leonardo
                  Ara&uacute;jo. Ele tem a vis&atilde;o de &aacute;guia: do
                  alto, v&ecirc; o horizonte amplo, mas &eacute; capaz de mirar
                  o detalhe que faz a diferen&ccedil;a. Foi com essas
                  habilidades que ele influiu na vis&atilde;o de mercado da Rede
                  Cidad&atilde;. A aplica&ccedil;&atilde;o das ferramentas de
                  proatividade trazidas pelo Prof. Leonardo nos ajudou a
                  avan&ccedil;ar em nossas estrat&eacute;gias voltadas para a
                  empregabilidade de pessoas de baixa renda&rdquo;.
                </p>
                <p>– FERNANDO ALVES, Diretor Executivo Rede Cidadã</p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;Uma constante em nossa atua&ccedil;&atilde;o &eacute; a
                  cultura organizacional centrada nas pessoas. A metodologia da
                  proatividade de mercado contribuiu para validar nosso
                  trabalho, apontando o caminho de constante melhoria. Empresas
                  proativas s&atilde;o movidas por pessoas proativas. Ficamos
                  muito felizes em ser um case do livro Estrat&eacute;gias
                  Proativas de Neg&oacute;cio&rdquo;.
                </p>
                <p>
                  – LÍDIA ABDALLA, Presidente Executiva do Laboratório Sabin
                </p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;No excelente contexto do MBA FDC, a aula de Marketing
                  do Prof. Leonardo Ara&uacute;jo consegue superar as mais
                  elevadas expectativas. Foi um dos pontos altos do programa.
                  Experimentamos o genu&iacute;no prazer do aprendizado, na sua
                  forma mais pura e agrad&aacute;vel. &rdquo;
                </p>
                <p>– MARCELO BRUNINI, Diretor Presidente UCBVET Saúde Animal</p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;Tive o privil&eacute;gio de assistir a algumas aulas e
                  palestras inspiradoras do Professor Leonardo Ara&uacute;jo na
                  FDC. &Eacute; um dos grandes professores que vi atuar em sala
                  de aula ao longo da minha extensa carreira de aluno e
                  professor. Possui uma did&aacute;tica &iacute;mpar e uma
                  flu&ecirc;ncia verbal encantadora. Al&eacute;m disso, carrega
                  consigo uma extraordin&aacute;ria experi&ecirc;ncia
                  profissional como executivo&rdquo;.&nbsp;&nbsp;
                </p>
                <p>
                  – ROMEO BUSARELLO, Diretor de Marketing e Ambientes Digitais
                  da Tecnisa
                </p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;Ao longo dos &uacute;ltimos anos, ingressamos no
                  projeto de crescimento sustent&aacute;vel do
                  Laborat&oacute;rio Sabin. Em seu trabalho conosco, Leonardo
                  Ara&uacute;jo trouxe ferramentas que confirmaram a nossa
                  habilidade de construir estrat&eacute;gias proativas de
                  mercado. Um diagn&oacute;stico fundamental para o presente e
                  futuro da nossa empresa. &rdquo;
                </p>
                <p>
                  – SANDRA COSTA E JANETE VAZ, Sócias Fundadoras do Grupo Sabin
                </p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;Nesses dez anos de trabalho em conjunto, na jornada da
                  proatividade de mercado, tenho aprendido muito com o colega e
                  amigo Leonardo. &Eacute; uma das pessoas mais proativas e
                  din&acirc;micas que j&aacute; conheci. A maneira como encara
                  os novos desafios e seu entusiasmo pelo trabalho bem feito
                  s&atilde;o contagiantes. Al&eacute;m disso, &eacute; poeta,
                  prova de que a compet&ecirc;ncia em gest&atilde;o
                  estrat&eacute;gica e o dom da poesia podem conviver de forma
                  harmoniosa. &rdquo;
                </p>
                <p>
                  – ROGÉRIO GAVA, Consultor de Empresas, Professor e Escritor
                </p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;O Professor Leonardo Ara&uacute;jo &eacute; uma
                  daquelas pessoas que devemos conhecer e se relacionar pelo
                  menos uma vez na vida. Tenho a sorte de poder dividir com ele
                  minhas ideias, ouvir suas grandes li&ccedil;&otilde;es e
                  experi&ecirc;ncias e contar com seus conselhos. Leonardo
                  &eacute; um otimista nato e um verdadeiro motivador. Uma
                  pessoa proativa em todos os momentos&rdquo;.
                </p>
                <p>– ROGÉRIO SALUME, CEO e Sócio Fundador da Wine.com.br</p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;O Prof.&nbsp; Leonardo Araujo nos trouxe ferramentas
                  relevantes de gest&atilde;o da inova&ccedil;&atilde;o, como o
                  Radar de Mudan&ccedil;as, provocando muitas reflex&otilde;es
                  em nossa &aacute;rea de intelig&ecirc;ncia de mercado. Os
                  sinais de mudan&ccedil;as captados alimentaram o pensamento
                  proativo de nossos gestores. Observamos tend&ecirc;ncias e
                  oportunidades de antecipa&ccedil;&atilde;o de mercado: um
                  mapeamento din&acirc;mico e estrat&eacute;gico que nos torna
                  mais competitivos. &rdquo;
                </p>
                <p>
                  – SUSANA CABUS, Diretora Presidente do Palato Supermercado
                </p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;Leonardo Ara&uacute;jo &eacute; um palestrante vibrante
                  e envolvente. Em 2015 palestrou em oito eventos de Encontros
                  de L&iacute;deres em que reunimos empres&aacute;rios e
                  gestores do setor supermercadista. Leonardo trouxe um
                  conte&uacute;do excelente e diferenciado com foco na
                  proatividade de mercado. Provocou nos varejistas presentes
                  muita reflex&atilde;o sobre a gest&atilde;o estrat&eacute;gica
                  de suas empresas. Falou de inova&ccedil;&atilde;o de forma
                  inovadora&rdquo;.
                </p>
                <p>– SERGIO ALVIM, Diretor Presidente - Supermercado Moderno</p>
              </div>
            </div>
            <div className="each-slide">
              <div>
                <p>
                  &ldquo;Enquanto executivo de marketing da Localiza, Leonardo
                  trouxe novos conceitos que foram aplicados nos diversos
                  segmentos de neg&oacute;cio da empresa, contribuindo para a
                  consolida&ccedil;&atilde;o de nossa lideran&ccedil;a de
                  mercado. Leonardo sempre teve uma cabe&ccedil;a al&eacute;m de
                  seu tempo e logo percebemos que era um executivo diferenciado,
                  com vis&atilde;o hol&iacute;stica e muita facilidade para
                  interpretar fatos e dados sobre gest&atilde;o de
                  neg&oacute;cios. &rdquo;
                </p>
                <p>– SALIM MATTAR, Executive Chairman Localiza</p>
              </div>
            </div>
          </Slide>
        </Col>
        <Col xs={12}></Col>
      </Row>
    </Container>
  );
};

export default Depoimentos;
