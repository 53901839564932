import React from "react";
import styles from "./Livros.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerLivros from "../../Assets/ilustracaoTopoLivros.jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import EmpresasProativas from "../../Assets/empresas_proativas.jpg";
import EstrategiasProativas from "../../Assets/estrategias_proativas_de_negocio.jpg";

const redirectIndex = () => {
  window.location.href = "/";
};

const buyEmpresasProativas = () => {
  window.open(
    "https://www.amazon.com.br/Empresas-Proativas-Antecipar-Mudan%C3%A7as-Mercado/dp/8535245693",
    "_blank"
  );
};
const buyEstrategiasProativas = () => {
  window.open(
    "https://www.amazon.com.br/Estrat%C3%A9gias-Proativas-Neg%C3%B3cio-Jos%C3%A9-Ara%C3%BAjo/dp/8535275185/ref=sr_1_1?ie=UTF8&qid=1463064256&sr=8-1&keywords=ESTRATEGIAS+PROATIVAS+DE+NEGOCIO",
    "_blank"
  );
};
const Livros = () => {
  const primeiraParteHistoria = () => {
    return (
      <Container className={styles.generalContent}>
        <Row>
          <Col xs={12}>
            <div className={styles.banner}>
              <img
                src={BannerLivros}
                alt="Banner de Livros de Leonardo Araujo"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}></Col>
          <Col xs={12} md={8}>
            <div className={styles.generalContent}>
              <div className={styles.headerSection}>
                <h1 className="titleSection">LIVROS</h1>
              </div>
            </div>
          </Col>
          <Col xs={12} md={2}></Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className={styles.contentArticle}>
              <div className={styles.contentBook}>
                <img src={EmpresasProativas} alt="Empresas Proativas" />
                <div>
                  <p>EMPRESAS PROATIVAS</p>
                  <p>
                    Um dos ganhadores do Prêmio Jabuti em 2012, mostra como o
                    mecanismo da antecipação de mercado pode ser posto em
                    prática. <br /> Oferece um modelo valioso repleto de
                    ferramentas para aplicação gerencial.
                  </p>
                </div>
              </div>
              <button
                className={styles.botaoComprar}
                onClick={buyEmpresasProativas}
              >
                Comprar
              </button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className={styles.contentArticle}>
              <div className={styles.contentBook}>
                <img src={EstrategiasProativas} alt="Empresas Proativas" />
                <div>
                  <p>EMPRESAS PROATIVAS DE NEGÓCIO</p>
                  <p>
                    A metáfora das quatro chaves – Estratégia | Marketing |
                    Inovação| Pessoas – ilustra como as empresas podem abrir as
                    portas do seleto clube das empresas proativas e saírem do
                    lugar comum da competição.
                  </p>
                </div>
              </div>
              <button
                className={styles.botaoComprar}
                onClick={buyEstrategiasProativas}
              >
                Comprar
              </button>
            </div>
          </Col>
        </Row>
        <Row>
            <Col>
              <div className={styles.generalContent}>
                <strong>
                  <a onClick={redirectIndex} style={{cursor:'pointer'}}>Voltar</a>
                </strong>
              </div>
            </Col>
          </Row>
      </Container>
    );
  };

  return (
    <>
      <Header />
      <div className="container">{primeiraParteHistoria()}</div>
      <Footer />
    </>
  );
};

export default Livros;
