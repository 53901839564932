import React from "react";
import styles from "./Palestras.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerPalestras from "../../Assets/ilustracaoTopoPalestras.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactHtmlParser from "html-react-parser";
const Palestras = (props) => {
  const speeches = []
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  // useEffect(() => {
  //   doSetAllSpeeches();
  // }, []);

  // const doSetAllSpeeches = async () => {
  //   await doGetAllSpeeches(dispatch).then((speeche) => {
  //     setSpeeches(speeche);
  //   });
  // };

  return (
    <Container id="Palestras">
      <Row>
        <Col xs={12}>
          <div className={styles.banner}>
            <img
              src={BannerPalestras}
              alt="Banner Palestras de Leonardo Araujo"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={1}></Col>
        <Col xs={12} md={10}>
          <div className={styles.generalContent}>
            <div className={styles.headerSection}>
              <h1 className="titleSection">PALESTRAS</h1>
              <p className="subTitleSection">
                Sempre me fascinou o poder das palavras quando carregadas de
                significado e de energia. Por isso mergulho com entusiasmo e
                muito fôlego em minhas palestras. Falo de temas que me desafiam
                todos os dias. Aprendo sempre, para ir mais fundo e não passar
                em branco.
              </p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={1}></Col>
      </Row>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        customTransition="all 0.5s"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {speeches
          ? speeches.map((speeche) => {
              return (
                <a href={`palestra/${speeche.id}`} className={styles.link}>
                  <div className={styles.sliderPalestras}>
                    <img src={speeche.image} alt="Palestras Leonardo Araujo" />
                    <h3>{speeche.title}</h3>
                    <h4>{speeche.subtitle}</h4>
                    <span>{ReactHtmlParser(speeche.abstract)}</span>
                  </div>
                </a>
              );
            })
          : ""}
      </Carousel>
    </Container>
  );
};

export default Palestras;
