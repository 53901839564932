import React from "react";
import styles from "./Conteudos.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BannerConteudos from "../../Assets/ilustracaoTopoConteudos.jpg";

const redirectArtigos = () => {
  window.location.href = "/artigos";
};

const redirectLivros = () => {
  window.location.href = "/livros";
};

const Conteudos = () => {
  return (
    <Container className={styles.generalContent} id="Conteudos">
      <Row>
        <Col xs={12}>
          <div className={styles.banner}>
            <img
              src={BannerConteudos}
              alt="Banner Conteúdos de Leonardo Araujo"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={1}></Col>
        <Col xs={12} md={10}>
          <div className={styles.secondContent}>
            <div className={styles.headerSection}>
              <h1 className="titleSection">CONTEÚDOS</h1>
              <p className="subTitleSection">
                Aqui mostro alguns conteúdos e ideias que nasceram de pesquisas,
                estudos e experiências profissionais nos temas de minha
                especialidade.
              </p>
            </div>
            <div className={styles.botoesConteudo}>
              <button
                className={styles.botaoArtigoELivros}
                onClick={redirectArtigos}
              >
                Artigos
              </button>
              <button
                className={styles.botaoArtigoELivros}
                onClick={redirectLivros}
              >
                Livros
              </button>
            </div>
          </div>
        </Col>
        <Col xs={12} md={1}></Col>
      </Row>
    </Container>
  );
};

export default Conteudos;
