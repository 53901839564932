import {
  getAllArticles,
  getArticle,
  deleteArticle,
  createArticle,
} from "../Services/Article";

export const doGetAllArticles = async (dispatch) => {
  try {
    return await getAllArticles();
  } catch (error) {
    return dispatch({
      type: "REQUEST_ERROR",
      payload: error,
    });
  }
};

export const doGetArticle = async (dispatch, articleId) => {
  try {
    return getArticle(articleId);
  } catch (error) {
    return dispatch({
      type: "REQUEST_ERROR",
      payload: error,
    });
  }
};


export const doCreateArticle = async (dispatch, articleInfos) => {
  try {
    return createArticle(articleInfos);
  } catch (error) {
    return dispatch({
      type: "REQUEST_ERROR",
      payload: error,
    });
  }
};

export const doDeleteArticle = async (dispatch, articleId) => {
  try {
    return deleteArticle(articleId);
  } catch (error) {
    return dispatch({
      type: "REQUEST_ERROR",
      payload: error,
    });
  }
};
